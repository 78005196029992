<template>
  <q-page>
    <div class="be-main-lr">
      <div class="be-page-title q-mt-md q-mb-md" v-html="pageStore.info.name"></div>
      <div class="q-mt-md" v-html="pageContent" />
    </div>
  </q-page>
</template>

<script>
import { createMetaMixin } from 'quasar'

import { mapState, mapStores } from 'pinia'
import { usePageStore } from 'stores/page'
import { useSettingsStore } from 'stores/settings'

export default {
  name: 'StaticPage',
  mixins: [
    createMetaMixin(function mymeta() {
      return {
        title:
          this.pageStore.info.seo_title !== null
            ? this.pageStore.info.seo_title
            : `${this.pageStore.info.name} - ${this.websiteName}`,
        meta: {
          description: {
            name: 'description',
            content:
              this.pageStore.info.seo_description !== null
                ? this.pageStore.info.seo_description
                : this.pageStore.info.description
          }
        }
      }
    })
  ],
  preFetch({ store, currentRoute, redirect }) {
    const pageStore = usePageStore(store)
    return new Promise(resolve => {
      if (currentRoute.params.type === 'about-us' || currentRoute.name === 'static-page-url') {
        pageStore.fetchByType(currentRoute.params.type).then(() => {
          if (pageStore.info.name === '404') {
            redirect(false, 404)
          }
          resolve()
        })
      } else {
        pageStore.fetchById(currentRoute.params.id).then(() => {
          resolve()
        })
      }
    })
  },
  computed: {
    ...mapStores(usePageStore),
    ...mapState(useSettingsStore, {
      websiteName: store => store.website.name
    }),
    pageContent() {
      if (this.pageStore.info.wysiwyg_editor === 'unlayer') {
        return JSON.parse(this.pageStore.info.content)
          .chunks.body.replace('<body ', '<div ')
          .replace('</body>', '</div>')
      }
      return this.pageStore.info.content
    }
  },
  beforeUnmount() {
    this.pageStore.clearInfo()
  },
  mounted() {
    if (this.pageStore.info.wysiwyg_editor === 'unlayer') {
      const style = document.createElement('style')
      style.textContent = JSON.parse(this.pageStore.info.content).chunks.css
      document.head.append(style)
    }
    // check if url has message, if yes, show it as notification
    if (typeof this.$route.query.message !== 'undefined') {
      this.$q.notify({
        message: this.$route.query.message,
        color:
          typeof this.$route.query.status !== 'undefined' && this.$route.query.status === 'OK'
            ? 'positive'
            : 'negative',
        position: 'top'
      })
    }
  }
}
</script>
